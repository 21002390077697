<script lang="ts" setup>
import { useServerHead } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { defineAsyncComponent } from 'vue'
import { useLocale } from '@/helpers.ts'

const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))

useServerHead(() => ({
  script: [
    {
      src: 'https://solar.otovo.com/js/search.js',
      async: true,
      defer: true,
    },
  ],
}))
</script>

<template>
  <zg-section>
    <iframe
      id="otovo-iframe"
      :src="`https://solar.otovo.com/${useLocale()?.[0]}/search?context=iframe&utm_source=Elskling&utm_campaign=Elskling-autumn-23&theme=default`"
      title="otovo-address-search"
      loading="lazy"
      width="100%"
      data-qa="iframe"
      style="height: 140px"
    />
  </zg-section>
</template>

<style lang="scss" scoped>
  #otovo-iframe {
    left: 0;
    border: 0;
    overflow: hidden;
  }
</style>
